<template>
  <validation-observer ref="simpleRules">
    <b-card>
      <h4><b>Security &amp; Privacy</b></h4>
      <hr />
      <!-- form -->
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group label="Old Password" label-for="account-old-password">
              <validation-provider
                #default="{ errors }"
                name="Old Password is required"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Old Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group label-for="account-new-password" label="New Password">
              <validation-provider
                #default="{ errors }"
                name="New Password is required"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Retype New Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password is required"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <hr />

          <b-col md="12">
            <div class="demo-inline-spacing">
              <b-form-checkbox v-model="selected" value="A">
                Send me status notifications
              </b-form-checkbox>
              <b-form-checkbox v-model="selected" value="B">
                Send me Offers &amp; Promotions
              </b-form-checkbox>
            </div>
          </b-col>

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click.prevent="onSubmit()"
            >
              Save changes
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </b-card>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ProfileServices from "@/apiServices/ProfileServices";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: null,
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },

    onSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          console.log(
            this.passwordValueOld +
              " : " +
              this.newPasswordValue +
              " : " +
              this.RetypePassword
          );

          if (this.newPasswordValue == this.RetypePassword) {
            const sendData = {
              old_password: this.passwordValueOld,
              new_password: this.RetypePassword,
            };

            ProfileServices.updatePassword(sendData)
              .then((response) => {
                this.productData = response.data.data;

                if (response.data.status) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message,
                      icon: "AlertIcon",
                      variant: "success",
                    },
                  });
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message,
                      icon: "AlertIcon",
                      variant: "danger",
                    },
                  });
                }
              })
              .catch((error) => {
                console.error("There was an error!", error);
              });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "New Password & Confirm Password does not match",
                icon: "AlertIcon",
                variant: "warning",
              },
            });
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Password is required",
              icon: "AlertIcon",
              variant: "warning",
            },
          });
        }
      });
    },
  },
};
</script>
