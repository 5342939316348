export const country_code = [
  {
    country_code: "IN",
    code: "+91",
    name: "India",
  },
  {
    country_code: "GB",
    code: "+44",
    name: "United Kingdom",
  },
  {
    country_code: "US",
    code: "+1",
    name: "United States / Canada",
  },
  {
    country_code: "AU",
    code: "+61",
    name: "Australia",
  },
  {
    country_code: "AD",
    code: "+43",
    name: "Austria",
  },
  {
    country_code: "AD",
    code: "+376",
    name: "Andorra",
  },
  {
    country_code: "AL",
    code: "+355",
    name: "Albania",
  },
  {
    country_code: "BY",
    code: "+375",
    name: "Belarus",
  },
  {
    country_code: "BG",
    code: "+359",
    name: "Bulgaria",
  },
  {
    country_code: "BA",
    code: "+387",
    name: "Bosnia and Herzegovina",
  },
  {
    country_code: "BE",
    code: "+32",
    name: "Belgium",
  },
  {
    country_code: "CZ",
    code: "+420",
    name: "Czech Republic",
  },
  {
    country_code: "HR",
    code: "+385",
    name: "Croatia",
  },
  {
    country_code: "DK",
    code: "+45",
    name: "Denmark",
  },
  {
    country_code: "EE",
    code: "+372",
    name: "Estonia",
  },
  {
    country_code: "FR",
    code: "+33",
    name: "France",
  },
  {
    country_code: "FI",
    code: "+358",
    name: "Finland",
  },
  {
    country_code: "GR",
    code: "+30",
    name: "Greece",
  },
  {
    country_code: "DE",
    code: "+49",
    name: "Germany",
  },
  {
    country_code: "HU",
    code: "+36",
    name: "Hungary",
  },
  {
    country_code: "IT",
    code: "+39",
    name: "Italy",
  },
  {
    country_code: "IE",
    code: "+353",
    name: "Ireland",
  },
  {
    country_code: "IS",
    code: "+354",
    name: "Iceland",
  },
  {
    country_code: "LU",
    code: "+352",
    name: "Luxembourg",
  },
  {
    country_code: "LI",
    code: "+423",
    name: "Liechtenstein",
  },
  {
    country_code: "LT",
    code: "+370",
    name: "Lithuania",
  },
  {
    country_code: "LV",
    code: "+371",
    name: "Latvia",
  },
  {
    country_code: "NL",
    code: "+31",
    name: "Netherlands",
  },
  {
    country_code: "NO",
    code: "+47",
    name: "Norway",
  },
  {
    country_code: "PL",
    code: "+48",
    name: "Poland",
  },
  {
    country_code: "PT",
    code: "+351",
    name: "Portugal",
  },
  {
    country_code: "RO",
    code: "+40",
    name: "Romania",
  },
  {
    country_code: "CH",
    code: "+41",
    name: "Switzerland",
  },
  {
    country_code: "SK",
    code: "+421",
    name: "Slovakia",
  },
  {
    country_code: "ES",
    code: "+34",
    name: "Spain",
  },
  {
    country_code: "SM",
    code: "+378",
    name: "San Marino",
  },
  {
    country_code: "SI",
    code: "+386",
    name: "Slovenia",
  },
  {
    country_code: "SE",
    code: "+46",
    name: "Sweden",
  },
  {
    country_code: "UA",
    code: "+380",
    name: "Ukraine",
  },
  {
    country_code: "AE",
    code: "+971",
    name: "United Arab Emirates",
  },
];