<template>
  <div>
    <loading
      :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />

    <b-card v-if="sellerInfo.user_country == ''">
      <p>
        Please update mobile number from Account Settings > Personal Details,
        and Try again.
      </p>
    </b-card>

    <!-- STRIPE_CARD_SECTION -->
    <b-card v-else-if="sellerInfo.user_country != '+91'">
      <h4><b>Payments</b></h4>
      <hr />
      <div v-if="form.user_is_stripe_pending === 1">
        <p>
          For users except India create your Stripe Account by Clicking on the
          button below for your Payout's
        </p>
        <b-button @click="getStripeLink()">
          Create Stripe Connect Account
        </b-button>
      </div>
      <div v-else>
        <b-alert variant="primary" show class="mt-1">
          <div class="alert-body">
            <span>Account is Ready!</span>
          </div>
        </b-alert>

        <b-card class="">
          <b-row>
            <!-- <b-col md="6" lg="6" sm="12">
              <h4>Name: {{ stripeUsername }}</h4>
            </b-col>

            <b-col md="6" lg="6" sm="12">
              <h4>Email: {{ stripeEmail }}</h4>
            </b-col>

            <b-col md="6" lg="6" sm="12">
              <h4>Phone: {{ stripePhone }}</h4>
            </b-col> -->

            <b-col md="6" lg="6" sm="12">
              <h4>Account Type: {{ stripeAccType }}</h4>
            </b-col>

            <b-col md="6" lg="6" sm="12">
              <h4>Account ID: {{ stripeAccID }}</h4>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-card>

    <!-- PayU_CARD_SECTION -->
    <!-- <b-card v-else>
      <h4><b>Payment Information</b></h4>
      <hr />
      <validation-observer ref="PamentInfoValidate">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Name on Pancard"
                rules="required"
              >
                <b-form-group label-for="pancard_name" label="Name On Pancard">
                  <b-form-input
                    id="pancard_name"
                    placeholder="Name On Pancard"
                    v-model="payu_form.pancard_name"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Pancard Number"
                rules="required"
              >
                <b-form-group label-for="pancard_number" label="Pancard Number">
                  <b-form-input
                    id="pancard_number"
                    placeholder="Pancard Number"
                    v-model="payu_form.pancard_number"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="gst_number" label="GST Number">
                <b-form-input
                  id="gst_number"
                  placeholder="GST Number"
                  v-model="payu_form.gst_number"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="business_name" label="Business Name">
                <b-form-input
                  id="business_name"
                  placeholder="Business Name"
                  v-model="payu_form.business_name"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Bank Account Number"
                rules="required"
              >
                <b-form-group
                  label-for="bank_account_number"
                  label="Bank Account Number"
                >
                  <b-form-input
                    id="bank_account_number"
                    placeholder="Bank Account Number"
                    v-model="payu_form.bank_account_number"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Bank Account Holder Name is required"
                rules="required"
              >
                <b-form-group
                  label-for="holder_name"
                  label="Bank Account Holder Name"
                >
                  <b-form-input
                    id="holder_name"
                    placeholder="Bank Account Holder Name"
                    v-model="payu_form.holder_name"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Bank Account IFSC Code is required"
                rules="required"
              >
                <b-form-group
                  label-for="ifsc_code"
                  label="Bank Account IFSC Code"
                >
                  <b-form-input
                    id="ifsc_code"
                    placeholder="Bank Account IFSC Code"
                    v-model="payu_form.ifsc_code"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                type="submit"
                @click="onSubmit"
              >
                Save changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card> -->

    <b-card v-else>
      <validation-observer ref="peresonalRules" tag="form">
        <b-row v-if="sellerInfo.user_country == '+91'">
          <h4><b>Payments</b></h4>
          <hr />
          <div v-if="form.user_is_stripe_pending === 1">
            <p>
              For users except India create your Stripe Account by Clicking on
              the button below for your Payout's
            </p>
            <b-button @click="getStripeLink()">
              Create Stripe Connect Account
            </b-button>
          </div>
          <div v-else>
            <b-alert variant="primary" show class="mt-1">
              <div class="alert-body">
                <span>Account is Ready!</span>
              </div>
            </b-alert>

            <b-card class="">
              <b-row>
                <b-col md="6" lg="6" sm="12">
                  <h4>Account Type: {{ stripeAccType }}</h4>
                </b-col>

                <b-col md="6" lg="6" sm="12">
                  <h4>Account ID: {{ stripeAccID }}</h4>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-row>

        <!-- <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Personal &amp; Bank Account Details</h5>
            <small class="text-muted"> Enter Details. </small>
          </b-col>
          <b-col md="6">
            <b-form-group label="Name on PAN Card" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="payu_form.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="AJAYKUMA JULESH CHAUHAN"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Email" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="payu_form.email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="yourname@company.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Phone Number" label-for="phone">
              <validation-provider
                #default="{ errors }"
                name="Phone Number"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="payu_form.phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="9876543210"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="PAN Number" label-for="pan_number">
              <validation-provider
                #default="{ errors }"
                name="PAN Number"
                rules="required"
              >
                <b-form-input
                  id="pan_number"
                  max="11"
                  min="11"
                  v-model="payu_form.pan_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Eg. MYPAN1234X"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Business Name (Optional)"
              label-for="business_name"
            >
              <b-form-input
                id="business_name"
                v-model="payu_form.business_name"
                placeholder="Eg. Delux Clothings"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="GST Number (Optional)" label-for="gst">
              <b-form-input
                id="gst"
                v-model="payu_form.gst_number"
                placeholder="Eg. 27AABCU9603R1ZN"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Bank Account Holder Name"
              label-for="bank_holder_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Bank Account Holder Name"
                rules="required"
              >
                <b-form-input
                  id="bank_holder_name"
                  v-model="payu_form.bank_holder_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="AJAYKUMAR JULESH CHAUHAN"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Bank Account Number"
              label-for="bank_account_number"
            >
              <validation-provider
                #default="{ errors }"
                name="Bank Account Number"
                rules="required"
              >
                <b-form-input
                  id="bank_account_number"
                  v-model="payu_form.bank_account_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="3456782345"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Bank Account IFSC Code"
              label-for="bank_account_ifsc"
            >
              <validation-provider
                #default="{ errors }"
                name="Bank Account IFSC Code"
                rules="required"
              >
                <b-form-input
                  id="bank_account_ifsc"
                  v-model="payu_form.bank_ifsc_code"
                  :state="errors.length > 0 ? false : null"
                  placeholder="SBIN0001234"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col lg="12" md="12" sm="12">
            <b-button variant="primary" @click="handleSubmit()">
              Save &amp; Coutinue
            </b-button>
          </b-col>
        </b-row> -->
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BAlert,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { required, email } from "@validations";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import stripeConnectServices from "@/apiServices/stripeConnectServices";
import PaymentServices from "@/apiServices/PaymentServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BAlert,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    Loading,
    FormWizard,
    TabContent,
  },
  directives: {
    Ripple,
  },
  /* props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  }, */
  data() {
    return {
      required,

      form: {
        user_stripe_id: null,
        user_is_stripe_pending: null,
      },

      stripeUsername: "",
      stripeEmail: "",
      stripePhone: "",
      stripeAccType: "",
      stripeAccID: "",

      payu_form: {
        name: null,
        email: null,
        phone: null,
        pan_number: null,
        gst_number: null,
        bank_account_number: null,
        bank_holder_name: null,
        bank_ifsc_code: null,
        business_name: null,
      },

      sellerInfo: null,

      isLoading: false,
      fullPage: true,
      currentTabStatus: "personal_account",
    };
  },
  methods: {
    async getStripeLink() {
      this.isLoading = true;

      try {
        const response = await stripeConnectServices.getStripeLink();
        this.isLoading = false;
        if (response.data.status) {
          //window.open(response.data.data.accountLink.url, "_blank").focus();
          //window.open(response.data.data.accountLink.url, "_self").focus();
          // Check if the user is using Chrome
          if (navigator.userAgent.indexOf("Chrome") != -1) {
            console.log("User is using Chrome");
            window.open(response.data.data.accountLink.url, "_blank").focus();
          } // Check if the user is using Safari
          else if (navigator.userAgent.indexOf("Safari") != -1 && navigator.userAgent.indexOf("Chrome") == -1) {
            console.log("User is using Safari");
            window.location.href = response.data.data.accountLink.url;
            //window.open(response.data.data.accountLink.url, "_self").focus();
          }else{
            window.open(response.data.data.accountLink.url, "_blank").focus();
          }
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },

    async getStripeAccountDetails() {
      try {
        const response = await stripeConnectServices.getStripeAccountDetails();
        console.log({ getStripeAccountDetails: response.data });
        if (response.data.status) {
          this.form.user_stripe_id = response.data.data.user_stripe_id;
          this.form.user_is_stripe_pending =
            response.data.data.user_is_stripe_pending;
          if (response.data.data.user_is_stripe_pending === 0) {
            let bank_response =
              await stripeConnectServices.getStripeBankAccountDetails();

            this.stripeUsername =
              bank_response &&
              bank_response.data &&
              bank_response.data.data &&
              bank_response.data.data.settings
                ? bank_response.data.data.settings &&
                  bank_response.data.data.settings.dashboard &&
                  bank_response.data.data.settings.dashboard.display_name
                : bank_response.data.data.dashboard &&
                  bank_response.data.data.dashboard.display_name;
            // bank_response.data.data.settings.dashboard&& bank_response.data.data.settings.dashboard.display_name

            this.stripeEmail = bank_response.data.data.email;
            this.stripePhone = bank_response.data.data.phone;
            this.stripeAccType = bank_response.data.data.type;
            this.stripeAccID = bank_response.data.data.id;
          }
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },

    /* this.$refs.itemFormsValidator.validate().then((success) => {
        if (success) { */

    handleDraft() {},

    async handleSubmit() {
      this.$refs.peresonalRules.validate().then(async (success) => {
        if (!success) return;
        try {
          this.isLoading = true;

          const response = await PaymentServices.createChildMerchant(
            this.payu_form
          );

          this.isLoading = false;

          console.log(response.data);
          if (response.data.status) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Payment Info Saved",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Payment Info Not Saved",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        } catch (err) {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Server Error",
              icon: "EditIcon",
              variant: "failure",
            },
          });
          console.log("Error in creating Merchant Account For seller ", err);
        }
      });
    },

    onCancel() {
      console.log("Seller cancelled the loader.");
    },

    /* *********** */

    setUserInfo() {
      if (this.sellerInfo.user_country == "+91") {
        this.payu_form.name =
          this.sellerInfo.user_firstname + " " + this.sellerInfo.user_lastname;
        this.payu_form.email = this.sellerInfo.user_email;
        this.payu_form.phone = this.sellerInfo.user_mobile;
        console.log(this.sellerInfo);
      }
    },
  },
  async beforeMount() {
    const checkStripeAccount = new URLSearchParams(window.location.search).get(
      "checkStripeAccount"
    );

    if (checkStripeAccount) {
      try {
        const response = await stripeConnectServices.verifyStripeAccount();
        // if (response.data.status) {
        //   this.getStripeAccountDetails();
        // }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    }
    this.getStripeAccountDetails();

    this.sellerInfo = this.$store.getters["user/getUserDetails"];

    this.setUserInfo();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
